.gradient {
  background: linear-gradient(
    to right,
    #bace2a,
    #3ab298,
    #1db5ce,
    #2971b0,
    #3c4292,
    #c07351,
    #f39425,
    #fcc52d
  );
}
